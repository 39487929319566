.visually-hidden {
	clip: rect(0 0 0 0);
	clip-path: inset(50%);
	height: 1px;
	overflow: hidden;
	position: absolute;
	white-space: nowrap;
	width: 1px;
}

button {
	all: unset;
	cursor: pointer;
}

.global-settings {
	border-bottom: 1px solid hsl(0, 0%, 80%);
	box-shadow: 0 -2px 4px hsl(0, 0%, 20%);
	height: 34px;
}

.global-settings.show-global-settings {
	height: auto;
	padding-top: 22px;
}

.global-settings-rows {
	display: none;
	flex-direction: column;
}

.show-global-settings .global-settings-rows {
	display: flex;
}

.global-settings-row {
	align-items: center;
	display: flex;
	flex-wrap: wrap;
	gap: 1em;
	justify-content: space-between;
	padding: 0 1em 22px;
}

.select-with-label,
.collection-select {
	flex: 1 1 0;
}

.select-with-label {
	display: flex;
	flex-direction: column;
}

.select-with-label .collection-select {
	margin-top: 4px;
}

label,
fieldset,
legend,
.details,
.font-title {
	font-size: 0.5rem;
	font-weight: bold;
	letter-spacing: 1px;
	text-transform: uppercase;
}

.details {
	align-items: center;
	color: rgba(0, 0, 0, 0.5);
	display: flex;
	gap: 22px;
	height: 100%;
	margin-left: 8px;
}

button {
	cursor: pointer;
}

.details button {
	background: none;
	border: 0;
	color: inherit;
	font: inherit;
	margin: 0;
	padding: 0;
	text-decoration: underline;
	text-transform: inherit;
}

.font-title {
	color: hsl(0, 0%, 80%);
	left: 0;
	pointer-events: none;
	position: absolute;
	right: 0;
	text-align: center;
	top: 26px;
}

input[type='radio'] {
	margin: 0 4px;
	position: relative;
}

input[type='number'] {
	border-radius: 4px;
	border: 1px solid hsl(0, 0%, 80%);
	color: hsl(0, 0%, 20%);
	font-size: inherit;
	margin-left: 12px;
	min-height: 41px;
	padding: 2px 8px;
	width: 5em;
}

.global-settings input[type='number'] {
	display: block;
	font-size: 0.85rem;
	margin: 4px 0 0;
}

.preview-text-input {
	flex-grow: 1;
	font: inherit;
	padding: 0.2em 0.4em;
	resize: none;
}

.alignment-options,
.list-mode-options {
	align-items: center;
	border: 0;
	display: flex;
	padding: 0;
}

:is(.alignment-options, .list-mode-options) legend {
	margin-bottom: 4px;
}

:is(.alignment-options, .list-mode-options) label {
	cursor: pointer;
	display: grid;
	font-size: 22px;
}

label > * {
	grid-area: 1 / 1;
}

.list-mode-options {
	padding-right: 6px;
}

.grid-container {
	display: flex;
	flex-grow: 1;
	overflow: hidden;
}

.font-containers {
	list-style: none;
}

.no-fonts-warning {
	color: rgba(0, 0, 0, 0.5);
	font-style: italic;
	justify-self: center;
	margin: auto;
	padding-bottom: 2em;
	text-align: center;
}
.no-fonts-warning strong {
	display: block;
	font-size: 2em;
	font-style: normal;
}

.start-comparison-button {
	background: #2ecc40;
	border-radius: 100px;
	border: 3px solid #29bb3a;
	bottom: 36px;
	box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
	color: #fff;
	font: inherit;
	font-weight: 600;
	left: 50%;
	padding: 0.7em 1.2em;
	position: fixed;
	transform: translateX(-50%);
}

.start-comparison-button-visible {
	padding-bottom: 300px;
}

.comparison-mode {
	height: 100dvh;
	width: 100vw;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}

.comparison-mode-font-name {
	position: absolute;
	bottom: 36px;
	padding: 0.7em;
	opacity: 0.3;
}

[type='checkbox'] {
	left: -9999px;
	position: absolute;
}

[type='checkbox'] + label {
	cursor: pointer;
	padding-left: 28px;
	position: relative;
	top: -11px;
}

[type='checkbox'] + label:before {
	background: #fff;
	border-radius: 100%;
	border: 2px solid;
	content: '';
	height: 18px;
	left: 0;
	position: absolute;
	top: 0;
	width: 18px;
}

[type='checkbox']:checked + label:after {
	border-radius: 100%;
	content: '';
	height: 14px;
	left: 4px;
	position: absolute;
	top: 4px;
	width: 14px;
}

.config-mode-toggle {
	position: absolute;
	top: 6px;
	right: 6px;
}

.show-font-toggle + label:before {
	border-color: #ff4136;
}

.show-font-toggle + label:after {
	background: #ff4136;
}

.marked-font-toggle + label:before {
	border-color: #2ecc40;
}

.marked-font-toggle + label:after {
	background: #2ecc40;
}

.radio-icon {
	padding: 2px;
}

.radio-icon.selected {
	background: #ffdc00;
}
