.font-container {
	padding: 20px;
	position: relative;
	text-align: center;
}

.font-settings {
	align-items: center;
	display: flex;
	margin: 0 auto 24px;
	max-width: 720px;
}

.select-font-variant {
	flex-grow: 1;
}

.font-actions {
	display: flex;
	margin-left: 12px;
}

.font-hover-settings {
	position: absolute;
	right: 12px;
	top: 12px;
}

.font-preview-wrapper {
	font-size: var(--font-preview-size);
}

.font-preview {
	border-radius: 12px;
	display: inline-block;
	line-height: var(--font-preview-line-height);
	margin: 0;
	max-width: 100%;
	overflow: auto;
	padding: 48px 24px;
	text-align: var(--font-preview-align);
	white-space: pre-wrap;
}

.font-preview.marked {
	background: rgba(46, 204, 64, 0.2);
}
